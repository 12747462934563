import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home.js";
import Demo from "./pages/Demo.js";
import CustomNavbar from "./components/CustomNavbar.js";
import VideoDemo from "./pages/VideoDemo.js";

function MainLayout({ children }) {
  return (
    <div className="bg-gray-100 min-h-screen flex pt-16 justify-center px-2 overflow-clip">
      <div className="relative w-full max-w-lg">
        <div className="absolute top-10 -left-4 w-96 h-96 bg-nebulom-pink rounded-full mix-blend-multiply filter blur-lg opacity-70 animate-blob" />
        <div className="absolute top-10 -right-4 w-80 h-80 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000" />
        <div className="absolute top-6 left-20 w-80 h-80 bg-nebulom-cyan rounded-full mix-blend-multiply filter blur-2xl opacity-70 animate-blob animation-delay-4000" />
        {/* <div className="absolute top-56  right-20 transform -rotate-33 ">
          <div className="animate-infinite-scroll-3">
            <div className="w-12 h-12 rounded-full bg-red-500 transition-transform mix-blend-multiply filter blur-md opacity-90 animate-ping animation-delay-4000 ease-in-out	"></div>
          </div>
        </div> */}
        <CustomNavbar />
        {children}
      </div>
    </div>
  );
}

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MainLayout>
            <Home />
          </MainLayout>
        }
      />
      <Route
        path="/demo"
        element={
          <MainLayout>
            <Demo />
          </MainLayout>
        }
      />
      <Route path="/demo/video" element={<VideoDemo />} />
    </Routes>
  );
}

export default App;
