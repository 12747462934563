import React from "react";
import { Card, CardBody, Typography } from "@material-tailwind/react";

function Home() {
  return (
    <div>
      <div className="m-8 relative space-y-4">
        <Card className="mt-6 w-96 mx-auto bg-white/20 shadow-lg ring-1 ring-black/5">
          <CardBody>
            <Typography variant="h5" color="blue-gray" className="mb-2">
              Kempenhaeghe / Nebulom DXD
            </Typography>
            <Typography className="text-gray-900 whitespace-pre-line">
              Welcome, to the digital expereinces workshop for Kempenhaeghe!
            </Typography>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
export default Home;
