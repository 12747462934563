import { NavLink } from "react-router-dom";
import { faHouse, faVrCardboard } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { Typography, Button } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CustomNavbar() {
  return (
    <div className="mx-auto px-6 py-2 bg-white/20 shadow-lg ring-1 ring-black/5 rounded-full flex align-items-center justify-center w-56 sm:w-96 md:w-full">
      <NavLink to="/" className="z-10 mr-6">
        {({ isActive }) => (
          <Button
            className={classNames(
              "mx-0 bg-transparent flex border-2 border-black pr-4 sm:pr-6 hover:backdrop-blur-lg hover:animate-pulse-blue align-items-center",
              {
                "bg-gradient-to-r from-cyan-200/30 to-blue-600/80 hover:bg-gradient-to-l":
                  isActive,
              },
              { "hover:bg-nebulom-cyan/40": !isActive }
            )}
            ripple={true}
            type="gradient"
          >
            <FontAwesomeIcon
              className="mr-2 pt-1"
              icon={faHouse}
              size="xl"
              color="black"
            />
            <Typography className="hidden sm:block text-justify text-black">
              Home
            </Typography>
          </Button>
        )}
      </NavLink>
      <NavLink className="z-10" to="/demo">
        {({ isActive }) => (
          <Button
            className={classNames(
              "flex bg-transparent border-2 border-black pr-4 sm:pr-6 hover:bg-nebulom-pink/40 hover:backdrop-blur-sm hover:animate-pulse-pink align-items-center",
              {
                "bg-gradient-to-r from-pink-300/30 to-purple-600/80 hover:bg-gradient-to-l":
                  isActive,
              },
              { "hover:bg-nebulom-pink/40": !isActive }
            )}
            ripple={true}
            type="gradient"
          >
            <FontAwesomeIcon
              className="mr-2 pt-1"
              icon={faVrCardboard}
              size="xl"
              color="black"
            />

            <Typography className="hidden sm:block text-justify text-black">
              Demo
            </Typography>
          </Button>
        )}
      </NavLink>
    </div>
  );
}

export default CustomNavbar;
