import React from "react";
import { Typography, Button } from "@material-tailwind/react";
import { NavLink } from "react-router-dom";
import playButton from "../play-stream.svg";
function Demo() {
  return (
    <div className="m-8 relative space-y-4">
      <div className="p-5 bg-white/20 shadow-lg ring-1 ring-black/5 rounded-lg flex items-center justify-between space-x-8">
        <div className="flex-1">
          <Typography variant="h5" color="blue-gray" className="mb-2">
            Walking with epilepsy
          </Typography>
        </div>
        <NavLink to="/demo/video" className="z-10 mr-6" href="/">
          <Button
            className="mx-0 flex items-center gap-3 bg-transparent hover:bg-red-200/50 hover:backdrop-blur-sm "
            ripple={true}
            type="gradient"
          >
            <img alt="play button" className="h-8 w-8" src={playButton} />
            <Typography className="hidden sm:block text-justify text-black">
              Play
            </Typography>
          </Button>
        </NavLink>
      </div>
      <div className="p-5 bg-white/20 shadow-lg ring-1 ring-black/5 rounded-lg flex items-center justify-between space-x-8">
        <div className="flex-1">
          <div className="h-4 w-56 bg-gray-300 rounded"></div>
        </div>
        <div>
          <div className="w-20 h-6 rounded-lg bg-yellow-300"></div>
        </div>
      </div>
      <div className="p-5 bg-white/20 shadow-lg ring-1 ring-black/5 rounded-lg flex items-center justify-between space-x-8">
        <div className="flex-1">
          <div className="h-4 w-44 bg-gray-300 rounded"></div>
        </div>
        <div>
          <div className="w-28 h-6 rounded-lg bg-pink-300"></div>
        </div>
      </div>
    </div>
  );
}
export default Demo;
